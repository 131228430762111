const _jsxFileName = "/Users/nico/Developer/Kunden/H&G Systems/opendash-instance-hg/src/js/components/manualinput/index.tsx";import React from "react";
import { useSource, useTranslation } from "@opendash/core";
import GreisManualInput from "./GreisManualInput";

export default function GreisManual() {
  const [source] = useSource();
  const t = useTranslation();

  return React.createElement(GreisManualInput, { spsid: source ? source.tag : "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 9}} );
}
