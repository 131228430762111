const _jsxFileName = "/Users/nico/Developer/Kunden/H&G Systems/opendash-instance-hg/src/js/components/manualinput/GreisManualInput.tsx"; function _nullishCoalesce(lhs, rhsFn) { if (lhs != null) { return lhs; } else { return rhsFn(); } }import React, { } from "react";
import { AdminToolbar } from "@opendash/ui";
import { AdminLayout } from "@opendash/core";

import { Parse } from "parse-hooks";
import dayjs from "dayjs";
import {
  Radio,
  Select,


  Descriptions,
  Typography,
  notification,
  DatePicker,
  Button,
  Input,
} from "antd";
const { Title } = Typography;
const { Option } = Select;

import { useTranslation } from "@opendash/core";
import utc from "dayjs/plugin/utc";
dayjs.extend(utc);



export default function GreisManualInput({ spsid }) {
  const t = useTranslation();
  const [api, contextHolder] = notification.useNotification();

  const [radio, setRadio] = React.useState(1);
  const [date, setDate] = React.useState(null);
  const [myDate, setMyDate] = React.useState(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [value, setValue] = React.useState();
  const [container, setContainer] = React.useState(null);
  const [containerValue, setContainerValue] = React.useState("");
  const [error, setError] = React.useState(0);

  const itemData = [];

  const openNotificationWithIcon = (
    type,
    title,
    description
  ) => {
    api[type]({
      message: title,
      description: description,
    });
  };

  const sendEntry = async () => {
    if (radio == 1 && !date) {
      setError(4); //No weight entered
      openNotificationWithIcon(
        "error",
        t("greis:manualinput.component.error"),
        t("greis:manualinput.component.error_date")
      );
      return;
    } else if (radio == 2 && !myDate) {
      setError(5); //No weight entered
      openNotificationWithIcon(
        "error",
        t("greis:manualinput.component.error"),
        t("greis:manualinput.component.error_date")
      );
      return;
    } else if (!value || value == 0) {
      setError(1); //No weight entered
      openNotificationWithIcon(
        "error",
        t("greis:manualinput.component.error"),
        t("greis:manualinput.component.error_weight")
      );
      return;
    } else if (container == null) {
      setError(2); //No container selected
      openNotificationWithIcon(
        "error",
        t("greis:manualinput.component.error"),
        t("greis:manualinput.component.error_container")
      );
      return;
    } else if (container == "other" && containerValue == "") {
      setError(3); //No container size entered
      openNotificationWithIcon(
        "error",
        t("greis:manualinput.component.error"),
        t("greis:manualinput.component.error_size")
      );
      return;
    }
    setError(0);

    var entryContainerSize = 0;
    if (container == "other") {
      entryContainerSize = Number(containerValue);
    } else {
      entryContainerSize = Number(container);
    }

    let entryDate = new Date();
    if (radio == 1) {
      entryDate = _nullishCoalesce(date, () => ( new Date()));
    } else {
      entryDate = myDate ? myDate.toDate() : new Date();
    }

    const entryWeight = _nullishCoalesce(value, () => ( 0));

    let entryFormat = {
      id: "iot.Manually-" + spsid + "-Emptying-Weight",
      parent: [],
      meta: {},
      name: "Status (" + spsid + ")",
      valueTypes: [
        { name: "Emptying Weight", unit: "Kg", type: "Number" },
        { name: "Container Size", unit: "m3", type: "Number" },
      ],
      source: spsid,
      values: [
        { date: entryDate.valueOf(), value: [entryWeight, entryContainerSize] },
      ],
    };

    let entryObject = new Parse.Object("HG_ManualInput");
    entryObject.set("user", Parse.User.current());
    entryObject.set("msg", entryFormat);
    entryObject.set("spsid", spsid);
    entryObject.set("success", false);

    const acl = new Parse.ACL();
    acl.setPublicReadAccess(false);
    acl.setPublicWriteAccess(false);
    acl.setRoleReadAccess("od-admin", true);
    acl.setRoleWriteAccess("od-admin", true);
    entryObject.setACL(acl);

    await entryObject.save();
    openNotificationWithIcon(
      "success",
      t("greis:manualinput.component.success"),
      t("greis:manualinput.component.success_text")
    );
    cancelEntry();
  };

  const cancelEntry = () => {
    setRadio(1);
    setDate(null);
    setMyDate(null);
    setIsOpen(false);
    setValue(null);
    setContainer(null);
    setContainerValue("");
  };

  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 162}}
      , contextHolder
      , React.createElement(AdminToolbar, {
        title: t("greis:manualinput.label"),
        description: t("greis:manualinput.description"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 164}}
      )
      , React.createElement('div', { className: "od-page-main", __self: this, __source: {fileName: _jsxFileName, lineNumber: 168}}
        /* Auswahlmodus */

        , React.createElement(Descriptions, { bordered: true, column: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 171}}
          , React.createElement(Descriptions.Item, {
            label: t("greis:manualinput.component.input_mode"),
            span: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 172}}

            , React.createElement(Radio.Group, {
              onChange: (e) => setRadio(e.target.value),
              value: radio,
              style: { marginTop: "10px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 176}}

              , React.createElement(Radio.Button, { value: 1, __self: this, __source: {fileName: _jsxFileName, lineNumber: 181}}
                , t("greis:manualinput.component.mode_auto")
              )
              , React.createElement(Radio.Button, { value: 2, __self: this, __source: {fileName: _jsxFileName, lineNumber: 184}}
                , t("greis:manualinput.component.mode_manual")
              )
            )
          )

          /* Auswahl für automatische Leerung */
          , radio === 1 && (
            React.createElement(Descriptions.Item, {
              label: t("greis:manualinput.component.auto_emptying"),
              span: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 192}}

              , React.createElement(Select, {
                style: { width: "100%", textAlign: "center" },
                value: date,
                onChange: (value) => setDate(value),
                placeholder: t(
                  "greis:manualinput.component.emptying_placeholder"
                ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 196}}

                , itemData.map((item) => (
                  React.createElement(Option, { key: item.date, value: item.date, __self: this, __source: {fileName: _jsxFileName, lineNumber: 205}}
                    , item.name
                  )
                ))
              )
            )
          )

          /* Datumsauswahl */
          , radio === 2 && (
            React.createElement(Descriptions.Item, {
              label: t("greis:manualinput.component.date_select"),
              span: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 215}}

              , React.createElement(DatePicker, {
                style: { width: "100%" },
                placeholder: t("greis:manualinput.component.enter_date"),
                value: myDate ? dayjs(myDate) : null,
                onChange: (date) => setMyDate(date ),
                open: isOpen,
                format: "DD.MM.YYYY",
                onOpenChange: (open) => setIsOpen(open), __self: this, __source: {fileName: _jsxFileName, lineNumber: 219}}
              )
            )
          )

          /* Eingabe für Gewicht */

          , React.createElement(Descriptions.Item, {
            label: t("greis:manualinput.component.enter_fill"),
            span: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 233}}

            , React.createElement(Input, {
              type: "number",
              value: _nullishCoalesce(value, () => ( 0)),
              onChange: (e) => setValue(parseInt(e.target.value)),
              maxLength: 50,
              minLength: 1,
              style: { textAlign: "center" },
              placeholder: t("greis:manualinput.component.weight_placeholder"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 237}}
            )
          )

          /* Auswahl für Containergewicht */

          , React.createElement(Descriptions.Item, {
            label: t("greis:manualinput.component.container_size"),
            span: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 250}}

            , React.createElement(Radio.Group, {
              value: container,
              onChange: (e) => setContainer(e.target.value),
              style: { width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 254}}

              , React.createElement(Radio, { value: "26", __self: this, __source: {fileName: _jsxFileName, lineNumber: 259}}, "26m³")
              , React.createElement(Radio, { value: "32", __self: this, __source: {fileName: _jsxFileName, lineNumber: 260}}, "32m³")
              , React.createElement(Radio, { value: "38", __self: this, __source: {fileName: _jsxFileName, lineNumber: 261}}, "38m³")
              , React.createElement(Radio, { value: "other", __self: this, __source: {fileName: _jsxFileName, lineNumber: 262}}
                , t("greis:manualinput.component.other_size")
              )
            )
            , container === "other" && (
              React.createElement(Input, {
                type: "number",
                value: containerValue,
                onChange: (e) => setContainerValue(e.target.value),
                maxLength: 50,
                minLength: 1,
                style: { textAlign: "center", marginTop: "10px" },
                placeholder: t(
                  "greis:manualinput.component.enter_container_size"
                ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 267}}
              )
            )
          )
          /* Senden und Abbrechen Buttons */
          , React.createElement(Descriptions.Item, { style: { textAlign: "right" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 281}}
            , React.createElement(Button, { type: "primary", onClick: sendEntry, __self: this, __source: {fileName: _jsxFileName, lineNumber: 282}}
              , t("greis:manualinput.component.button_send")
            ), " ", " "

            , React.createElement(Button, { type: "default", onClick: cancelEntry, __self: this, __source: {fileName: _jsxFileName, lineNumber: 286}}
              , t("greis:manualinput.component.button_cancel")
            )
          )
        )
      )
    )
  );
}
