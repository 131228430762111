const _jsxFileName = "/Users/nico/Developer/Kunden/H&G Systems/opendash-instance-hg/src/js/components/transponders/GreisTransponderImport.tsx";import React, { useState, useEffect } from "react";

import { parse, } from "papaparse";

import { Modal, Upload, Alert, Button } from "antd";

import { useTranslation } from "@opendash/core";


export default function GreisTranspondersImport({
  open,
  close,
  addTransponders,
  overwriteTransponders,
}) {
  const t = useTranslation();
  const [file, setFile] = useState();
  const [csv, setCsv] = useState();

  useEffect(() => {
    if (file) {
      parse(file, {
        header: true,
        skipEmptyLines: "greedy",
        complete: (result) => {
          setCsv(result);
        },
      });
    } else {
      setCsv(undefined);
    }
  }, [file]);

  const clearAndClose = () => {
    setFile(undefined);
    close();
  };

  return (
    React.createElement(Modal, {
      title: t("greis:transponders.admin.import"),
      visible: open,
      onOk: (e) => clearAndClose(),
      onCancel: (e) => clearAndClose(),
      footer: [
        React.createElement(Button, { key: "back", onClick: (e) => clearAndClose(), __self: this, __source: {fileName: _jsxFileName, lineNumber: 46}}
          , t("greis:transponders.admin.import_action_cancel")
        ),
        React.createElement(Button, {
          key: "import_action_add",
          type: "primary",
          disabled: !csv || csv.errors.length > 0,
          onClick: (e) => {
            addTransponders(
              csv.data.map((row) => ({
                id: row[csv.meta.fields[0]],
                misc: row[csv.meta.fields[2]],
                misc2: row[csv.meta.fields[3]],
                owner: row[csv.meta.fields[1]],
              }))
            );

            clearAndClose();
          },
          children: t("greis:transponders.admin.import_action_add"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
        ),
        React.createElement(Button, {
          key: "import_action_overwrite",
          type: "primary",
          disabled: !csv || csv.errors.length > 0,
          onClick: (e) => {
            overwriteTransponders(
              csv.data.map((row) => ({
                id: row[csv.meta.fields[0]],
                owner: row[csv.meta.fields[1]],
                misc: row[csv.meta.fields[2]],
                misc2: row[csv.meta.fields[3]],
              }))
            );

            clearAndClose();
          },
          children: t("greis:transponders.admin.import_action_overwrite"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 67}}
        ),
      ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}

      , React.createElement(Preview, { preview: csv, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}} )

      , React.createElement(Upload.Dragger, {
        multiple: false,
        fileList: file ? [file] : [],
        beforeUpload: (file) => {
          setFile(file);
          return false;
        },
        onRemove: (file) => {
          setFile(undefined);
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}

        , React.createElement('p', { className: "ant-upload-text", __self: this, __source: {fileName: _jsxFileName, lineNumber: 100}}
          , t("greis:transponders.admin.import_upload_text")
        )
        , React.createElement('p', { className: "ant-upload-hint", __self: this, __source: {fileName: _jsxFileName, lineNumber: 103}}
          , t("greis:transponders.admin.import_upload_hint")
        )
      )
    )
  );
}

function Preview({ preview }) {
  const t = useTranslation();

  const style = { marginBottom: 24 };

  if (!preview) {
    return null;
  }

  if (preview.errors.length > 0) {
    return (
      React.createElement(Alert, {
        type: "error",
        style: style,
        message: t("greis:transponders.admin.import_bad_csv"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 122}}
      )
    );
  }

  return (
    React.createElement(Alert, {
      type: "info",
      style: style,
      message: t("greis:transponders.admin.import_success", {
        count: preview.data.length,
      }), __self: this, __source: {fileName: _jsxFileName, lineNumber: 131}}
    )
  );
}
