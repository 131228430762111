import "antd/dist/reset.css";
import "./highcharts.config";
import "./parse.config";

import { init, StorageAdapterLS } from "@opendash/core";
import { getCurrentLanguageSync } from "@opendash/i18n";
import { registerIconPack } from "@opendash/icons";
import { HighchartsPlugin } from "@opendash/plugin-highcharts";
import { MonitoringPlugin } from "@opendash/plugin-monitoring";
import { FeedbackPlugin } from "@opendash/plugin-feedback";
import { OpenwarePlugin } from "@opendash/plugin-openware";
import { $parse, ParsePlugin } from "@opendash/plugin-parse";
import { ParseMonitoringPlugin } from "@opendash/plugin-parse-monitoring";
import { TimeseriesPlugin } from "@opendash/plugin-timeseries";
import TransponderAdminRoute from "./components/transponders";
import RuntimeMonitorRoute from "./components/runtimemonitor";
import ManualInputRoute from "./components/manualinput";
import ServiceCurrentRoute from "./components/service/GreisServiceCurrent";
import ServiceViewRoute from "./components/service/GreisServiceView";
import dayjs from "dayjs";

dayjs.locale(getCurrentLanguageSync());

init("opendash", async (factory) => {
  // @ts-ignore
  registerIconPack(await import("@opendash/icons/dist/fa-regular.json"));

  factory.ui.setLogoImage("/hg.jpg");

  // Translations:
  factory.registerLanguage("en", "English");
  factory.registerLanguage("de", "Deutsch", "en", true);

  factory.registerAntDesignTranslation(
    "en",
    () => import("antd/lib/locale/en_US")
  );

  factory.registerAntDesignTranslation(
    "de",
    () => import("antd/lib/locale/de_DE")
  );

  // Adapter + Plugins

  factory.registerDeviceStorageAdapter(new StorageAdapterLS());

  await factory.use(new TimeseriesPlugin());
  await factory.use(new MonitoringPlugin());

  await factory.use(
    new ParsePlugin({
      authLdapActive: false,
      useLiveQueries: false,
      disableNavigationWidgetPreset:true,
    })
  );
  await factory.use(
    new ParseMonitoringPlugin({
      liveQueries: false,
    })
  );

  await factory.use(
    new OpenwarePlugin({
      secure: true,
      host: "hg.openinc.de",
      filterValuesOlderThanMS: 15742800000,
      disableFeature: {
        menu: {
          DataMeta: true,
          DataHierachies: true,
        },
        slideshow: true,
        dataCollection: false,
        VKPI: true,
        reporting: false,
        reportingFeatures: {
          adhoc:false,
          schedule:false,
          img:true,
          export:false
        }
      },
    })
  );

  await factory.use(new HighchartsPlugin());
  await factory.use(
    new FeedbackPlugin({
      feedbackURL: "https://linear.apps.openinc.dev/api/issues?team=HGS",
      feedbackAssigned: "94f2781b-eec5-4362-ba0c-c47a6822fc13",
    })
  );

  //Translation
  factory.registerTranslationResolver(
    "de",
    "parse-custom",
    async () => await import("./translations/de.json")
  );

  factory.registerTranslationResolver(
    "en",
    "parse-custom",
    async () => await import("./translations/en.json")
  );

  factory.registerTranslationResolver(
    "de",
    "greis",
    async () => await import("./translations/greis/de.json")
  );

  factory.registerTranslationResolver(
    "en",
    "greis",
    async () => await import("./translations/greis/en.json")
  );
  
  factory.registerStaticNavigationGroup({
    label: "openware:reporting.label",
    order: 30,
    id: "admin/reporting",
  });

 //Navigation
 factory.registerRoute({
  path: "/greis/transponders/*",
  component: async () => ({ default: TransponderAdminRoute }),
});

factory.registerRoute({
  path: "/greis/runtime-monitor/*",
  component: async () => ({ default: RuntimeMonitorRoute }),
});

factory.registerRoute({
  path: "/greis/manual-input/*",
  component: async () => ({ default: ManualInputRoute }),
});

factory.registerRoute({
  path: "/greis/service-view/current/*",
  component: async () => ({ default: ServiceCurrentRoute }),
});

factory.registerRoute({
  path: "/greis/service-view/all/*",
  component: async () => ({ default: ServiceViewRoute }),
});

factory.registerStaticNavigationItem({
  id: "hg_tranponders",
  group: "hg/transponders",
  place: "frontpage",
  order: 10,
  label: "greis:frontpage.transponder",
  icon: "fa:credit-card",
  color: "#fec000",
  link: "/greis/transponders",
  event: "string",
  permission: "hg:transponders",
  routeCondition: "**",
  activeCondition: "/",
});

factory.registerStaticNavigationItem({
  id: "hg_runtimemonitor",
  group: "hg/runtimemonitor",
  place: "frontpage",
  order: 10,
  label: "greis:frontpage.runtime_monitor",
  icon: "fa:bell",
  color: "#fec000",
  link: "/greis/runtime-monitor",
  event: "string",
  permission: "hg:runtime-monitor",
  routeCondition: "**",
  activeCondition: "/",
});

factory.registerStaticNavigationItem({
  id: "hg_manualinput",
  group: "hg/manualinput",
  place: "frontpage",
  order: 10,
  label: "greis:frontpage.manual_input",
  icon: "fa:keyboard",
  color: "#fec000",
  link: "/greis/manual-input",
  event: "string",
  permission: "hg:manual-input",
  routeCondition: "**",
  activeCondition: "/",
});

//Service
factory.registerStaticNavigationGroup({
  id: "hg_serviceview",
  label: "greis:frontpage.service_view_group",
  icon: "fa:tools",
  order: 50,
});

factory.registerStaticNavigationItem({
  id: "hg_serviceview",
  group: "hg/serviceview",
  place: "frontpage",
  order: 10,
  label: "greis:frontpage.service_view",
  icon: "fa:tools",
  color: "#fec000",
  link: "/greis/service-view/current",
  event: "string",
  permission: "hg:service-view",
  routeCondition: "**",
  activeCondition: "/",
});

factory.registerStaticNavigationItem({
  id: "greis_service_current",
  group: "hg_serviceview",
  place: "sidebar",
  order: 10,
  label: "greis:serviceview.menu_current",
  icon: "fa:clock",
  link: "/greis/service-view/current",
  permission: "hg:service-view",
  routeCondition: "/greis/service-view/**",
  activeCondition: "/greis/service-view/current",
});

factory.registerStaticNavigationItem({
  id: "greis_service_all",
  group: "hg_serviceview",
  place: "sidebar",
  order: 20,
  label: "greis:serviceview.menu_all",
  icon: "fa:table",
  link: "/greis/service-view/all",
  permission: "hg:service-view",
  routeCondition: "/greis/service-view/**",
  activeCondition: "/greis/service-view/all",
});

//Parse Tenant
factory.registerStaticNavigationItem({
  id: "admin/parse/tenant",
  group: "admin/parse",
  place: "sidebar",
  order: 1,
  label: "parse-admin:classes.OD3_Tenant.label",
  icon: "fa:building",
  link: "/admin/parse/OD3_Tenant",
  routeCondition: "/admin/**",
  activeCondition: "/admin/parse/OD3_Tenant",
  permission: "parse-admin",
});

//Class Config


  $parse.ui.setClassConfig({
    className: "OD3_WidgetPreset",
    titleFields: ["label"],
    displayFields: ["label", "tags", "description"],
    createFields: [],
    editFields: ["label", "tags", "description"],
  });

  $parse.ui.setClassConfig({
    className: "_User",
    titleFields: ["name"],
    displayFields: ["name", "tenant", "username", "email" ,"tenantAdmin", "updatedAt"],
    createFields: ["name", "username", "email", "tenant" ,"password","tenantAdmin"],
    editFields: ["name", "username", "email", "tenant" ,"password","tenantAdmin"],
  });

  $parse.ui.setDefaultView("OD3_WidgetPreset", {
    type: "table",
  });
  $parse.ui.setClassConfig({
    className: "OD3_Source",
    titleFields: ["name"],
    displayFields: ["name", "machineNumber", "tag", "type", "price", "weighing", "parent"],
    createFields: ["name", "machineNumber", "tag", "type", "price", "weighing", "parent"],
    editFields: ["name", "machineNumber", "type", "price", "weighing", "parent"],
  });
  $parse.ui.setClassConfig({
    className: "OD3_Tenant",
    titleFields: ["label"],
    displayFields: ["label"],
    createFields: ["label"],
    editFields: ["label"],
  });

  $parse.ui.setClassConfig({
    className: "OD3_Permission",
    titleFields: ["label"],
    displayFields: ["label", "key"],
    createFields: ["label", "key"],
    editFields: ["label", "key"],
  });

  $parse.ui.setClassConfig({
    className: "OD3_Dashboard",
    titleFields: ["name"],
    displayFields: ["name", "source"],
    createFields: ["name"],
    editFields: ["name"],
  });

  $parse.ui.setDefaultView("OD3_Source", {
    type: "table",
  });

  $parse.ui.setClassConfig({
    className: "_Role",
    titleFields: ["label"],
    displayFields: ["label", "name", "users", "createdAt", "updatedAt"],
    createFields: ["label", "name"],
    editFields: ["label", "name", "users"],
    defaultACL: () => {
      if (!$parse.user.id()) {
        return {};
      }

      return {
        [$parse.user.id()]: {
          read: true,
          write: true,
        },
      };
    },
  });
  $parse.ui.setDefaultView("_Role", {
    type: "table",
  });

  
}).then((app) => {
  console.log("init open.DASH");
});
