const _jsxFileName = "/Users/nico/Developer/Kunden/H&G Systems/opendash-instance-hg/src/js/components/runtimemonitor/GreisOperatingHoursSelect.tsx";import React, { } from "react";
import styled from "styled-components";

import { AdminToolbar } from "@opendash/ui";
import { AdminLayout } from "@opendash/core";
import { Parse } from "parse-hooks";
import { Icon } from "@opendash/icons";
import dayjs from "dayjs";
import {
  Alert,
  Button,
  Descriptions,
  Badge,
  Modal,
  Input,
  Statistic,
  Select,
  InputNumber,
  notification,
  Col,
  Popconfirm,
  Row,
} from "antd";

const { Option } = Select;

import { useTranslation, copy } from "@opendash/core";

const ButtonGroup = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: flex-end;

  > button {
    margin-left: 8px;
  }
`;

export default function GreisOperatingHoursSelect({
  spsid,
  admin,
  config,
  user,
  name,
}) {
  let { result, loading, error } = config;

  const [parseObj, setparseObj] = React.useState(result);
  const t = useTranslation();

  const [data, setData] = React.useState([]);
  const [userData, setUserData] = React.useState();
  const [showDataNow, setShowDataNow] = React.useState(0);
  const [showDataNowDate, setShowDataDate] = React.useState(0);
  const [showData24, setShowData24] = React.useState(0);
  const [showData60, setShowData60] = React.useState(0);
  const [showData724, setShowData724] = React.useState(0);
  const [showData770, setShowData770] = React.useState(0);
  const [showDataIndex, setShowDataIndex] = React.useState(0);

  const [opConfig, setOPConfig] = React.useState({});
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [deviationSet, setDeviationSet] = React.useState(false);

  const [hasError, setHasError] = React.useState(false);
  const [active, setActive] = React.useState(false);
  const [actionType, setActionType] = React.useState();
  const [difference, setDifference] = React.useState(0);
  const [userMail, setUserMail] = React.useState("");

  const [saveactionType, setSaveActionType] = React.useState();
  const [savedifference, setSaveDifference] = React.useState(0);
  const [saveuserMail, setSaveUserMail] = React.useState("");

  const [configExist, setConfigExist] = React.useState(false);

  if (!spsid) {
    return (
      React.createElement(Alert, {
        type: "info",
        message: t("greis:operatinghours.ui.select_location_first"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}
      )
    );
  }

  let translateType = [
    t("greis:operatinghours.ui.daylie"),
    t("greis:operatinghours.ui.weekly"),
  ];

  React.useEffect(() => {
    if (result && Object.keys(result.get("config")).length != 0) {
      setOPConfig(copy(result.get("config")));
      setConfigExist(true);
      setActive(copy(result.get("status")));
      setDifference(copy(result.get("config").diff));
      setUserMail(copy(result.get("config").mail));
      setActionType(copy(result.get("config").type));
    } else {
      setOPConfig({});
      setConfigExist(false);
      setActive(false);
    }
  }, [result]);

  let baseURL = "https://hg.openinc.de";
  let itemURL = "/api/data/items";
  let historyURL = "/api/data/historical";

  React.useEffect(() => {
    if (user != userData) {
      setUserData(user);
    }
  }, [user]);

  React.useEffect(() => {
    if (userData) {
      let isCancelled = false;
      const getData = async (spsid) => {
        const response = await fetch(
          baseURL + itemURL + "/" + user.getUsername() + "/" + spsid,
          {
            method: "GET",
            headers: {
              "OD-Session": user.getSessionToken(),
            },
          }
        );

        if (!response.ok) {
          throw new Error("Bad response from backend");
        }

        const body = await response.json();
        if (!isCancelled) {
          setData(body);
        }
      };

      getData(spsid).catch(console.error);

      return () => {
        isCancelled = true;
      };
    }
  }, [userData]);

  React.useEffect(() => {
    if (data.length > 0) {
      data.forEach((dataItem) => {
        let indexOfItem = 0;
        dataItem["valueTypes"].forEach((valueType) => {
          if (
            valueType["name"].includes("Betriebsstunden") ||
            valueType["name"].includes("Operating") ||
            valueType["name"].includes("Operational")
          ) {
            //Operating Hours
            setShowDataIndex(indexOfItem);
            setShowDataNow(dataItem.values[0].value[indexOfItem]);
            setShowDataDate(dataItem.values[0].date);
            getHistoryData(
              dataItem,
              dataItem["id"],
              dayjs(Date.now()).startOf("day").subtract(24, "h"),
              dayjs(Date.now()).startOf("day"),
              spsid,
              showDataIndex,
              1
            ).catch(console.error);
            getHistoryData(
              dataItem,
              dataItem["id"],
              dayjs(Date.now()).startOf("day").subtract(60, "d"),
              dayjs(Date.now()).startOf("day"),
              spsid,
              showDataIndex,
              2
            ).catch(console.error);
            getHistoryData(
              dataItem,
              dataItem["id"],
              dayjs(Date.now()).startOf("week").add(1, "d").subtract(7, "d"),
              dayjs(Date.now()).startOf("week").add(1, "d"),
              spsid,
              showDataIndex,
              3
            ).catch(console.error);

            getHistoryData(
              dataItem,
              dataItem["id"],
              dayjs(Date.now()).startOf("week").add(1, "d").subtract(77, "d"),
              dayjs(Date.now()).startOf("week").add(1, "d").subtract(7, "d"),
              spsid,
              showDataIndex,
              4
            ).catch(console.error);

            indexOfItem++;
          }
        });
      });
    }
  }, [data]);

  const getHistoryData = async (
    data,
    id,
    start,
    end,
    spsid,
    dimension,
    type
  ) => {
    const response = await fetch(
      baseURL + historyURL + spsid + "/" + id + "/" + start + "/" + end /*+
        "?mode=minmax&dimension=" +
        dimension +
        "&maxValues=20"*/,
      {
        method: "GET",
        headers: {
          "OD-Session": user.getSessionToken(),
        },
      }
    );

    if (!response.ok) {
      throw new Error("Bad response from backend");
    }

    const body = await response.json();

    if (type == 1) {
      //24 Stunden
      console.log(body.values);
      let value24 =
        body.values.length > 0
          ? body.values[body.values.length - 1].value[showDataIndex] -
            body.values[0].value[showDataIndex]
          : 0;
      setShowData24(value24);
    } else if (type == 2) {
      //60 Tage
      let value60 =
        body.values.length > 0
          ? Math.floor(
              (body.values[body.values.length - 1].value[showDataIndex] -
                body.values[0].value[showDataIndex]) /
                60
            )
          : 0;
      setShowData60(value60);
    } else if (type == 3) {
      // 7 Tage
      let value724 =
        body.values.length > 0
          ? body.values[body.values.length - 1].value[showDataIndex] -
            body.values[0].value[showDataIndex]
          : 0;
      setShowData724(value724);
    } else if (type == 4) {
      // 70 Tage
      let value770 =
        body.values.length > 0
          ? Math.floor(
              (body.values[body.values.length - 1].value[showDataIndex] -
                body.values[0].value[showDataIndex]) /
                10
            )
          : 0;
      setShowData770(value770);
    }
  };

  const openNotificationWithIcon = (type, title, message) => {
    notification[type]({
      message: title,
      description: message,
    });
  };

  const showModal = () => {
    try {
      setSaveActionType(copy(actionType));
      setSaveDifference(copy(difference));
      setSaveUserMail(copy(userMail));
    } catch (e) {
      console.log("First Start....", e);
    }

    if (difference > 0) {
      if (actionType == "1") {
        console.log("Action Type 1");
        let upperCorner = showData60 + showData60 * (difference / 100);
        let lowerCorner = showData60 - showData60 * (difference / 100);
        if (showData24 > upperCorner || showData24 < lowerCorner) {
          setDeviationSet(true);
        }
      } else if (actionType == "2") {
        console.log("Action Type 2");
        let upperCorner = showData770 + showData770 * (difference / 100);
        let lowerCorner = showData770 - showData770 * (difference / 100);
        if (showData724 > upperCorner || showData724 < lowerCorner) {
          setDeviationSet(true);
        }
      }
    }

    setIsModalOpen(true);
  };

  const delConfig = () => {
    try {
      parseObj.destroy().then(
        (e) => {
          setparseObj(null);
          setOPConfig({});
          setConfigExist(false);
          setActive(false);
          setActionType(null);
          setDifference(0);
          setUserMail("");
          setSaveActionType(null);
          setSaveDifference(0);
          setSaveUserMail("");
          console.log(parseObj);
        },
        (error) => {
          console.log("Parse Error deleting Object...");
        }
      );
      openNotificationWithIcon(
        "info",
        t("greis:operatinghours.ui.config_deleted"),
        t("greis:operatinghours.ui.config_deleted_text")
      );
    } catch (e) {
      console.log("Delete Error....", e);
    }
  };

  const setActiveStatus = (e) => {
    console.log(result);
    parseObj.set("status", e);
    parseObj.save().then((f) => {
      setOPConfig(copy(parseObj.get("config")));
      setConfigExist(true);
      setActive(e);
      setActionType(copy(actionType));
      setDifference(copy(difference));
      setUserMail(copy(userMail));
      setparseObj(f);
      if (e) {
        openNotificationWithIcon(
          "info",
          t("greis:operatinghours.ui.monitoring_active"),
          t("greis:operatinghours.ui.monitoring_active_text")
        );
      } else {
        openNotificationWithIcon(
          "info",
          t("greis:operatinghours.ui.monitoring_not_active"),
          t("greis:operatinghours.ui.monitoring_not_active_text")
        );
      }
    });
  };

  const saveConfig = () => {
    try {
      parseObj.set("spsid", spsid);
      parseObj.set("status", active);
      let configObject = {
        type: saveactionType,
        mail: saveuserMail,
        diff: savedifference,
      };
      parseObj.set("config", configObject);
      parseObj.save().then((e) => {
        setOPConfig(copy(parseObj.get("config")));
        setConfigExist(true);
        setActionType(copy(saveactionType));
        setDifference(copy(savedifference));
        setUserMail(copy(saveuserMail));
        openNotificationWithIcon(
          "success",
          t("greis:operatinghours.ui.config_saved"),
          t("greis:operatinghours.ui.config_saved_text")
        );
        setparseObj(e);
      });
    } catch (e) {
      setActive(true);
      const OService = Parse.Object.extend("HG_Runtime");
      result = new OService();
      result.set("spsid", spsid);
      result.set("status", true);
      let configObject = {
        type: saveactionType,
        mail: saveuserMail,
        diff: savedifference,
      };
      result.set("config", configObject);

      var acl = new Parse.ACL(user);
      acl.setReadAccess(user, true);
      acl.setWriteAccess(user, true);
      acl.setReadAccess("role:Admin", true);
      acl.setWriteAccess("role:Admin", true);
      acl.setPublicWriteAccess(false);
      acl.setPublicReadAccess(false);

      result.setACL(acl);

      result.save().then((e) => {
        setOPConfig(copy(result.get("config")));
        setConfigExist(true);
        setActionType(copy(saveactionType));
        setDifference(copy(savedifference));
        setUserMail(copy(saveuserMail));
        openNotificationWithIcon(
          "success",
          t("greis:operatinghours.ui.config_saved"),
          t("greis:operatinghours.ui.config_saved_text")
        );
        setparseObj(e);
      });
    }
  };

  const changeNumber = (number, actionTyper) => {
    if (number > 0) {
      if (actionTyper == "1") {
        let upperCorner = showData60 + showData60 * (number / 100);
        let lowerCorner = showData60 - showData60 * (number / 100);

        console.log(
          "Upper: " +
            upperCorner +
            " - Lower: " +
            lowerCorner +
            " | Value: " +
            showData24
        );

        if (showData24 > upperCorner || showData24 < lowerCorner) {
          setDeviationSet(true);
        } else {
          setDeviationSet(false);
        }
      } else if (actionTyper == "2") {
        let upperCorner = showData770 + showData770 * (number / 100);
        let lowerCorner = showData770 - showData770 * (number / 100);
        if (showData724 > upperCorner || showData724 < lowerCorner) {
          setDeviationSet(true);
        } else {
          setDeviationSet(false);
        }
      }
    }
  };

  const handleOk = () => {
    if (saveactionType && saveactionType > 0 && saveuserMail.length > 4) {
      //Save Values in Parse
      saveConfig();
      setIsModalOpen(false);
    } else {
      setHasError(true);
    }
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let getAction = (admin, active, configExist) => {
    let actions = [];
    if (admin && !active) {
      actions.push(
        React.createElement(Button, {
          disabled: !configExist,
          type: "primary",
          icon: React.createElement(Icon, { icon: "fa:check", __self: this, __source: {fileName: _jsxFileName, lineNumber: 486}} ),
          children: t("greis:operatinghours.ui.button_activate"),
          onClick: () => {
            setActiveStatus(true);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 483}}
        )
      );
    } else if (admin && active) {
      actions.push(
        React.createElement(Button, {
          danger: true,
          type: "dashed",
          icon: React.createElement(Icon, { icon: "fa:pause", __self: this, __source: {fileName: _jsxFileName, lineNumber: 498}} ),
          children: t("greis:operatinghours.ui.button_deactivate"),
          onClick: () => {
            setActiveStatus(false);
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 495}}
        )
      );
    }

    if (admin) {
      actions.push(
        React.createElement(Button, {
          icon: React.createElement(Icon, { icon: "fa:pen", __self: this, __source: {fileName: _jsxFileName, lineNumber: 510}} ),
          onClick: showModal,
          children: t("greis:operatinghours.ui.button_edit"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 509}}
        )
      );
    }

    if (admin && configExist) {
      actions.push(
        React.createElement(Popconfirm, {
          title: t("greis:operatinghours.ui.button_delete_text"),
          onConfirm: delConfig,
          okText: t("greis:operatinghours.ui.button_delete_yes"),
          cancelText: t("greis:operatinghours.ui.button_delete_no"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 519}}

          , React.createElement(Button, {
            danger: true,
            icon: React.createElement(Icon, { icon: "fa:trash", __self: this, __source: {fileName: _jsxFileName, lineNumber: 527}} ),
            children: t("greis:operatinghours.ui.button_delete"), __self: this, __source: {fileName: _jsxFileName, lineNumber: 525}}
          )
        )
      );
    }

    return actions;
  };

  return (
    React.createElement(AdminLayout, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 538}}
      , React.createElement(AdminToolbar, {
        title: t("greis:operatinghours.label"),
        description: t("greis:operatinghours.description"),
        actions: getAction(admin, active, configExist), __self: this, __source: {fileName: _jsxFileName, lineNumber: 539}}
      )
      , React.createElement('div', { className: "od-page-main", __self: this, __source: {fileName: _jsxFileName, lineNumber: 544}}
        , React.createElement(Modal, {
          title: t("greis:operatinghours.ui.modal_title"),
          visible: isModalOpen,
          onOk: handleOk,
          onCancel: handleCancel,
          footer: [
            React.createElement(Button, { key: "back", onClick: handleCancel, __self: this, __source: {fileName: _jsxFileName, lineNumber: 551}}
              , t("greis:operatinghours.ui.modal_cancel")
            ),
            React.createElement(Button, { key: "submit", type: "primary", onClick: handleOk, __self: this, __source: {fileName: _jsxFileName, lineNumber: 554}}
              , t("greis:operatinghours.ui.modal_save")
            ),
          ], __self: this, __source: {fileName: _jsxFileName, lineNumber: 545}}

          , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 559}}
            , React.createElement(Select, {
              placeholder: t("greis:operatinghours.ui.modal_placeholder"),
              style: { width: "100%" },
              value: saveactionType,
              onChange: (e) => {
                setSaveActionType(e);
                setHasError(false);
                changeNumber(savedifference, e);
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 560}}

              , React.createElement(Option, { value: "1", __self: this, __source: {fileName: _jsxFileName, lineNumber: 570}}
                , t("greis:operatinghours.ui.modal_daylie")
              )
              , React.createElement(Option, { value: "2", __self: this, __source: {fileName: _jsxFileName, lineNumber: 573}}
                , t("greis:operatinghours.ui.modal_weekly")
              )
            )
          )
          , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 578}}
            , React.createElement(InputNumber, {
              style: { width: "100%" },
              placeholder: t("greis:operatinghours.ui.modal_diff"),
              min: 0,
              max: 1000,
              value: savedifference,
              onChange: (e) => {
                changeNumber(e, saveactionType);
                setSaveDifference(e);
              },
              formatter: (value) => `${value}%`, __self: this, __source: {fileName: _jsxFileName, lineNumber: 579}}
            )
          )
          , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 592}}
            , React.createElement(Input, {
              placeholder: t("greis:operatinghours.ui.modal_mail"),
              value: saveuserMail,
              onChange: (e) => {
                setSaveUserMail(e.target.value);
                setHasError(false);
              },
              prefix: 
                React.createElement(Icon, { icon: "fa:envelope", style: { marginRight: "5px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 601}} )
              , __self: this, __source: {fileName: _jsxFileName, lineNumber: 593}}
            )
          )

          , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 606}}
            , saveactionType == 1 && (
              React.createElement(Alert, {
                message: t("greis:operatinghours.ui.modal_actionday_text"),
                type: "info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 608}}
              )
            )
            , saveactionType == 2 && (
              React.createElement(Alert, {
                message: t("greis:operatinghours.ui.modal_actionweek_text"),
                type: "info", __self: this, __source: {fileName: _jsxFileName, lineNumber: 614}}
              )
            )
            , deviationSet && (
              React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 620}}
                , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 621}} )
                , React.createElement(Alert, {
                  message: t("greis:operatinghours.ui.modal_hint"),
                  type: "warning", __self: this, __source: {fileName: _jsxFileName, lineNumber: 622}}
                )
              )
            )
          )

          , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 630}}
            , hasError && (
              React.createElement(Alert, {
                message: t("greis:operatinghours.ui.modal_error"),
                type: "error", __self: this, __source: {fileName: _jsxFileName, lineNumber: 632}}
              )
            )
          )
        )
        , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 639}}
          , React.createElement(Col, { md: 24, xl: 24, __self: this, __source: {fileName: _jsxFileName, lineNumber: 640}}
            , !active && configExist && (
              React.createElement(Alert, {
                message: t("greis:operatinghours.ui.noti_deactive"),
                type: "error", __self: this, __source: {fileName: _jsxFileName, lineNumber: 642}}
              )
            )
          )
        )
        , React.createElement(Descriptions, { bordered: true, column: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 649}}
          , React.createElement(Descriptions.Item, {
            label: t("greis:operatinghours.ui.table_source"),
            span: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 650}}

            , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 654}}
              , name, " | " , spsid, " "
            )
          )
          , React.createElement(Descriptions.Item, {
            label: t("greis:operatinghours.ui.table_ophours"),
            span: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 658}}

            , showDataNow, " " , t("greis:operatinghours.ui.table_details_hours")
          )
          , React.createElement(Descriptions.Item, {
            label: t("greis:operatinghours.ui.table_date"),
            span: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 664}}

            , dayjs(showDataNowDate).year() >= 2000
              ? `${dayjs(showDataNowDate).format("DD.MM.YYYY")} | ${dayjs(
                  showDataNowDate
                ).format("HH:mm:ss")}`
              : "-"
          )
          , React.createElement(Descriptions.Item, {
            label: t("greis:operatinghours.ui.table_status"),
            span: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 674}}

            , active && React.createElement(Badge, { status: "success", text: "Status", __self: this, __source: {fileName: _jsxFileName, lineNumber: 678}} )
            , !active && React.createElement(Badge, { status: "error", text: "Status", __self: this, __source: {fileName: _jsxFileName, lineNumber: 679}} )
          )
          , React.createElement(Descriptions.Item, {
            label: 
              t("greis:operatinghours.ui.table_details") +
              " (" +
              t("greis:operatinghours.ui.table_details_hours") +
              ")"
            ,
            span: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 681}}

            , React.createElement(Row, { gutter: 14, __self: this, __source: {fileName: _jsxFileName, lineNumber: 690}}
              , React.createElement(Col, { span: 10, style: { borderRight: "1px solid lightgray" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 691}}
                , React.createElement(Statistic, {
                  title: t("greis:operatinghours.ui.table_details_yesterday"),
                  value: showData24,
                  valueStyle: {
                    color: showData24 >= showData60 ? "#3f8600" : "#cf1322",
                  },
                  prefix: 
                    showData24 >= showData60 ? (
                      React.createElement(Icon, { icon: "fa:arrow-up", __self: this, __source: {fileName: _jsxFileName, lineNumber: 700}} )
                    ) : (
                      React.createElement(Icon, { icon: "fa:arrow-down", __self: this, __source: {fileName: _jsxFileName, lineNumber: 702}} )
                    )
                  ,
                  style: { textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 692}}
                )
              )
              , React.createElement(Col, { span: 10, __self: this, __source: {fileName: _jsxFileName, lineNumber: 708}}
                , React.createElement(Statistic, {
                  title: t("greis:operatinghours.ui.table_details_lastweek"),
                  value: showData724,
                  valueStyle: {
                    color: showData724 >= showData770 ? "#3f8600" : "#cf1322",
                  },
                  prefix: 
                    showData724 >= showData770 ? (
                      React.createElement(Icon, { icon: "fa:arrow-up", __self: this, __source: {fileName: _jsxFileName, lineNumber: 717}} )
                    ) : (
                      React.createElement(Icon, { icon: "fa:arrow-down", __self: this, __source: {fileName: _jsxFileName, lineNumber: 719}} )
                    )
                  ,
                  style: { textAlign: "center" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 709}}
                )
              )
            )
            , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 726}} )
            , React.createElement(Row, { gutter: 14, __self: this, __source: {fileName: _jsxFileName, lineNumber: 727}}
              , React.createElement(Col, { span: 10, style: { borderRight: "1px solid lightgray" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 728}}
                , React.createElement(Statistic, {
                  title: t("greis:operatinghours.ui.table_details_60d"),
                  value: showData60,
                  style: { textAlign: "center" },
                  prefix: React.createElement(Icon, { icon: "fa:arrow-right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 733}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 729}}
                )
              )
              , React.createElement(Col, { span: 10, __self: this, __source: {fileName: _jsxFileName, lineNumber: 736}}
                , React.createElement(Statistic, {
                  title: t("greis:operatinghours.ui.table_details_last10week"),
                  value: showData770,
                  style: { textAlign: "center" },
                  prefix: React.createElement(Icon, { icon: "fa:arrow-right", __self: this, __source: {fileName: _jsxFileName, lineNumber: 741}} ), __self: this, __source: {fileName: _jsxFileName, lineNumber: 737}}
                )
              )
            )
          )
          , configExist && (
            React.createElement(Descriptions.Item, {
              label: t("greis:operatinghours.ui.table_config"),
              span: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 747}}

              , t("greis:operatinghours.ui.table_config_periode"), ":", " "
              , translateType[actionType - 1]
              , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 753}} )
              , t("greis:operatinghours.ui.table_config_diff"), ": " , difference, "%"
              , React.createElement('br', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 755}} )
              , t("greis:operatinghours.ui.table_config_mail"), ": " , userMail
            )
          )
          , !configExist && (
            React.createElement(Descriptions.Item, {
              label: t("greis:operatinghours.ui.table_config"),
              span: 3, __self: this, __source: {fileName: _jsxFileName, lineNumber: 760}}

              , React.createElement('b', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 764}}, t("greis:operatinghours.ui.table_no_config"))
            )
          )
        )
      )
    )
  );
}
